/*.logo {
  width: 120px;
  height: 31px;
  background: rgba(255,255,255,.2);
  margin: 16px 24px 16px 0;
  float: left;
}*/

body {
  background: #f0f2f5;
}

.ant-form-item-children a {
  text-decoration: none !important; 
}

.pull-right {
  float: right;
}

.login-form {
  max-width: 350px;
  margin: 100px auto;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.hide {
  display: none;
}

.org-select {
  margin-left: 30px;
  margin-top: 15px;
  width: 200px;
}

.ant-avatar>img {
  width: auto !important;
}

.ant-select-dropdown-menu-item-group-title {
  color: #333;
  font-size: 14px;
}

.ant-drawer .ant-alert {
  margin-bottom: 20px;
}

.rc-color-picker-wrap {
  margin-top: 6px;
}

.rc-color-picker-wrap span{
  width: 50px;
}

.reg .ant-tabs-bar {
  display: none;
}

.ant-list-item-action {
  margin-top: -25px;
}

.logo {
  width: 120px;
  height: 40px;
  margin: 12px 24px 12px 0;
  float: left;
  background-size: cover !important;
}

.public .ant-tabs-nav-wrap,.public .ant-tabs-bar{
  display: none;
}

.driver .ant-upload{
  width: 100%;
}

.disptach .side .ant-card-body{
  height: calc(100% - 40px);
  overflow: scroll;
  overflow-x: hidden;
}

.jobs .ant-card-body{
  overflow: scroll;
  overflow-x: hidden;
  height: calc(100% - 40px);
}

.jobs .ant-card-extra{
  flex: 1;
}

.red, .red a{
  color: red;
}

.yellow a{
  color:#ad6400;
}

.disptach .ant-list-item{
  cursor: pointer;
  padding: 12px;
}

.selected{
  background: #e6faff;/*rgba(24, 144, 255, 0.2);*/
}

.sigCanvas.red{
  border: 1px solid red;
}

.small{
  font-size: 90%;
}

.ant-steps-item-title{
  font-size: 14px;
}

.full .ant-upload{
  width: 100%;
}

.bubble {
  padding: 12px 15px;
  background-color: #ffffff;
  -webkit-border-radius: 20px 20px 20px 0px;
  -moz-border-radius: 20px 20px 20px 0px;
  border-radius: 20px 20px 20px 0px;
  position: relative;
  margin-left: 10px;
  max-width: 600px;
  border: solid 1px #d9d9d9;
  display: inline-block;
}

.bubble.reverse {
  margin-right: 10px;
  margin-left: 0;
  background-color: #e6faff;
  -webkit-border-radius: 20px 20px 0 20px;
  -moz-border-radius: 20px 20px 0 20px;
  border-radius: 20px 20px 0 20px;
  text-align: left;
}

.scroll{
  height: calc(80vh - 145px) !important;
}

.scroll-container{
  position: absolute;top: 0px;left: 0px;right: 0px;bottom: 0px;overflow: scroll;margin-right: -17px;margin-bottom: -17px;
}

.side .ant-badge-count{
  min-width: 17px;
  height: 17px;
  padding: 0 4px;
  font-size: 11px;
  line-height: 17px;
}

.ant-input-affix-wrapper .ant-input-suffix{
  color: rgba(0, 0, 0, 0.3);
}

.ant-table-filter-dropdown-link.confirm{
  margin-right: 5px;
}

.ant-form-item br {
  content: "";
  margin: -15px;
  display: block;
}

.ql-editor br{
  margin: 0px;
  content: none;
}

.fr .ant-form-item-label > label::after{
  content: ' :'
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ant-table-body{
    overflow: scroll;
  }
}

.impair {
  background: #fafafa;
}

 .hasInstructions .ant-table-row-expand-icon{
  background: rgba(0, 0, 0, 0.25);
}

.tracing .container .container_num{
 color: #ff00f2 !important;
}

.dropped_terminal .container .container_num{
  color: purple !important;
}

.dropped_customer .container .container_num{
  color: orange !important;
 }

 .ready_pickup .container .container_num{
  color: blue !important;
 }

 .invoiced .container .container_num{
  color: lime !important;
 }

 .ready_terminal .container .container_num{
  color: red !important;
 }

 .customer_yard .container .container_num{
  color: darkorange !important;
 }

 .prepull_yard .container .container_num{
  color: darkorange !important;
 }

 @media only screen and (max-width: 750px) {
  .choose-class {
    width: 100% !important;
  }
  .capture{
    display: none;
  }

  .dob{
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 20px;
  }

  .ant-layout-content{
    padding: 0 !important;
  }

 /* .side .ant-avatar{
    display: none;
  }

  .side .ant-card-body{
    padding: 0px;
  }

  .side .ant-list-item-meta-avatar{
    margin-right: 0px;
    margin-top: -2px;
  }*/

  .ant-drawer-content-wrapper{
    width: 100% !important;
  }

  .ant-table-body{
    overflow: scroll;
  }

  .ant-calendar-input-wrap {
    display: none;
  }

  .scroll{
    margin-top: 40px;
  }

  .sms-view .chassis{
    display: none !important;
  }

  .sms-view .drivers{
    height: 100% !important;
  }

  .donation .rccs__card{
    width: 100%;
  }

  .donation .rccs{
    max-width: 100%;
  }
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  text-align: center;
}

.data-grid{
  width: 100%;
}

.btn-menu {
  position: absolute;
  top: 20px;
  right: 0px;
  transform: translate(-50%, -50%);
  width: 30px;
  cursor: pointer;
}

.btn-menu span {
  display: block;
  width: 100%;
  /*box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);*/
  border-radius: 3px;
  height: 4px;
  margin-top: 5px;
  background: #1890ff;
  transition: all .3s;
  position: relative;
}

.antd-pro-charts-pie-percent{
  display: none;
}

.antd-pro-charts-pie-pie .antd-pro-charts-pie-value{
  right: unset;
}

.driver .anticon{
  margin-left: 20px;
}

.ant-form-item-control .ql-picker-label,.ant-form-item-control .ql-toolbar.ql-snow{
  line-height: 15px;
}

.ql-editor{
  min-height: 140px;
}

.include_label label{
  white-space: normal;
  line-height: 2;
  margin-right: 15px;
  left: left;
  display: inline-block;
}

.center-element{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ant-form-item-label{
  text-align: left;
}

.ant-form-item label{
  white-space: normal;
}

.ant-drawer-body .ant-avatar img{
  width: 100% !important;
}

.dsg-add-row-input{
  width: 50px;
}

.suggest-btn{
  display: inline-block;
  padding: 3px 11px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 95%;
}